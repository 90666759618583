import {IGalleryControllerConfig} from '../types/galleryTypes';
import {GalleryStore} from './GalleryStore';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {ProductsService} from '../services/ProductsService';
import {I$W, IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {IControllerFactoryConfig} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {MAX_COLS, MAX_ROWS} from '../constants';

function getInitialProductsCountToFetch(isMobile: boolean, rows = 6, cols = 4) {
  if (isMobile) {
    return 10;
  }
  return rows * cols;
}

export function galleryController(controllerFactoryConfig: IControllerFactoryConfig): IWidgetController {
  let galleryStore: GalleryStore;
  const {compId, config, setProps, context: factoryContext, type, reportError} = controllerFactoryConfig;
  const {
    style: {styleParams},
  } = config;

  return {
    pageReady: () => {
      const styleParamsWithDefaults = getStyleParamsWithDefaults(styleParams, () =>
        getStyleParamsWithDefaultsFunc({style: {styleParams}, dimensions: undefined})
      );

      const isMobile = factoryContext.siteStore.isMobile();
      let initialProductsCount = getInitialProductsCountToFetch(
        isMobile,
        styleParamsWithDefaults.numbers.galleryRows,
        styleParamsWithDefaults.numbers.galleryColumns
      );

      if (factoryContext.siteStore.isEditorMode()) {
        initialProductsCount = MAX_ROWS * MAX_COLS;
      }

      const productsService = new ProductsService(factoryContext.siteStore, initialProductsCount, 'Grid Gallery');
      galleryStore = new GalleryStore(
        styleParamsWithDefaults,
        config,
        setProps,
        factoryContext.siteStore,
        productsService,
        compId,
        type,
        reportError
      );
      return galleryStore.setInitialState().catch(reportError);
    },
    updateConfig: async (
      _$w: I$W,
      {style: {styleParams: newStyleParams}, publicData: newPublicData}: IGalleryControllerConfig
    ) => {
      await galleryStore.updateState(newStyleParams, newPublicData);
    },
  };
}
