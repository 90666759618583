export const query = `query getCategoryProducts($compId: String!, $limit: Int!, $offset:Int = 0) {
  catalog {
    category(compId: $compId) {
      id
      name
      productsWithMetaData(limit: $limit, offset: $offset, onlyVisible: true) {
        list {
          id
          options(limit: 1) {
            id
          }
          productType
          ribbon
          price
          comparePrice
          isInStock
          urlPart
          formattedComparePrice
          formattedPrice
          digitalProductFileItems {
            fileType
          }
          name
          media {
            url
            index
            width
            mediaType
            altText
            title
            height
          }
        }
        totalCount
      }
    }
  }
}
`;
