import {ISortingOption} from '../types/galleryTypes';
import _ from 'lodash';
import {sortingOptions} from '../constants';
import {ProductSortField, SortDirection} from '@wix/wixstores-graphql-schema';

export class SortService {
  private selectedSort: ISortingOption;

  constructor() {
    this.selectedSort = sortingOptions[0];
  }

  public getSelectedSort = (): ISortingOption => {
    return this.selectedSort;
  };

  public setSelectedSort = (sortId: string): ISortingOption => {
    this.selectedSort = _.find(sortingOptions, ['id', sortId]);
    return this.selectedSort;
  };

  public getSort = (sortId: string): ISortingOption => {
    if (sortId === 'Default') {
      return _.find(sortingOptions, ['id', 'default']);
    } else {
      return _.find(sortingOptions, ['id', sortId]);
    }
  };

  public getSortDTO = (sort: ISortingOption | null) => {
    return sort && sort.field
      ? {
          direction: sort.direction === 'ASC' ? SortDirection.Ascending : SortDirection.Descending,
          sortField: sort.field as ProductSortField,
        }
      : null;
  };
}
