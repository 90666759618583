export const query = `query getData($externalId: String!, $compId: String!, $limit: Int!, $sort: ProductSort, $filters: ProductFilters, $offset: Int) {
  appSettings(externalId: $externalId) {
    widgetSettings
  }
  catalog {
    category(compId: $compId) {
      id
      name
      productsWithMetaData(limit: $limit, onlyVisible: true, sort: $sort, filters: $filters, offset: $offset) {
        list {
          id
          options(limit: 1) {
            id
          }
          productType
          ribbon
          price
          comparePrice
          isInStock
          urlPart
          formattedComparePrice
          formattedPrice
          digitalProductFileItems {
            fileType
          }
          name
          media {
            url
            index
            width
            mediaType
            altText
            title
            height
          }
        }
        totalCount
      }
    }
  }
}
`;
