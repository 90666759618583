import {FilterEqOperation, FilterType, IFilterDTO, IFilterModel, IFilterOption} from '../types/galleryTypes';

export class ListFilterModel implements IFilterModel {
  public title: string;
  public options: IFilterOption[];
  public filterId: number;
  public activeOptions: string[] = [];
  public selectedOptions: string[] = [];

  constructor(
    public readonly filterType: FilterType.LIST_OPTION,
    title: string,
    options: IFilterOption[],
    private readonly field: string
  ) {
    this.title = title;
    this.options = options;
  }

  public updateActiveOptions(optionId: string): void {
    if (this.activeOptions.indexOf(optionId) === -1) {
      this.activeOptions.push(optionId);
    } else {
      const index = this.activeOptions.indexOf(optionId);
      this.activeOptions.splice(index, 1);
    }
  }

  public toDTO(): IFilterDTO {
    return {
      field: this.field,
      op: FilterEqOperation.IN,
      values: this.activeOptions,
    };
  }

  public hasActiveOptions(): boolean {
    return !!this.activeOptions.length;
  }

  public resetActiveOptions(): void {
    this.activeOptions = [];
  }
}
