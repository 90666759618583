import {ILocation} from '@wix/native-components-infra/dist/es/src/types/types';
import {FilterModel, FilterType, IFilterModel, IQueryParamsFilter, ISorting} from '../types/galleryTypes';
import {PriceFilterModel} from '../models/PriceFilterModel';

export class QueryParamsService {
  private readonly location: ILocation;
  private readonly currentUrlSearchParams: {title: string; value: string}[];

  constructor(location) {
    this.location = location;
    this.currentUrlSearchParams = [];
  }

  public readonly getFiltersFromQueryParams = (filterModels: FilterModel[]): IQueryParamsFilter[] => {
    const searchParamsDecoded = this.getSearchParamsDecoded(this.location.query);
    const activeFilters = [];

    filterModels.forEach(filter => {
      if (searchParamsDecoded[filter.title]) {
        switch (filter.filterType) {
          case FilterType.LIST_OPTION:
          case FilterType.CUSTOM_COLLECTION:
          case FilterType.COLOR_OPTION: {
            if (searchParamsDecoded[filter.title] !== 'Default') {
              activeFilters.push({
                key: filter.title,
                value: searchParamsDecoded[filter.title],
                filterId: filter.filterId,
              });
            }
            break;
          }
          case FilterType.COLLECTION: {
            activeFilters.push({
              key: filter.title,
              value: searchParamsDecoded[filter.title],
              filterId: filter.filterId,
            });
            break;
          }
          case FilterType.PRICE: {
            const priceValue = searchParamsDecoded[filter.title].split('-');
            activeFilters.push({
              key: filter.title,
              value: `${priceValue[0]}|${priceValue[1]}`,
              filterId: filter.filterId,
            });
          }
        }
        this.setAndUpdateQueryParams(filter.title, searchParamsDecoded[filter.title]);
      }
    });

    return activeFilters.length > 0 ? activeFilters : null;
  };

  public readonly getQueryParam = (title: string): string => {
    const queryParams = this.location.query;
    this.setAndUpdateQueryParams(title, queryParams[title]);
    return queryParams[title];
  };

  public readonly updateQueryParamsByPage = (offset: number, productPerPage: number): void => {
    const page = (offset + productPerPage) / productPerPage;
    this.setAndUpdateQueryParamsInLocation('page', page.toString());
  };

  public readonly updateQueryParamsBySort = (selectedSort: ISorting) => {
    if (selectedSort.id === 'default') {
      this.setAndUpdateQueryParamsInLocation('sort', 'Default');
    } else {
      this.setAndUpdateQueryParamsInLocation('sort', selectedSort.id);
    }
  };

  public readonly updateQueryParamsByFilters = (
    filterId: number,
    filterModels: FilterModel[],
    mainCollectionId: string
  ) => {
    const filterModel = filterModels.find(fm => fm.filterId === filterId) as IFilterModel;
    let activeFilterOption: string = '';

    switch (filterModels[filterId].filterType) {
      case FilterType.COLLECTION: {
        if (filterModel.activeOptions === mainCollectionId) {
          activeFilterOption = `All`;
        } else {
          const filterOption = filterModel.options.find(option => option.key === filterModel.activeOptions);
          activeFilterOption = filterOption.value;
        }
        break;
      }
      case FilterType.LIST_OPTION:
      case FilterType.CUSTOM_COLLECTION:
      case FilterType.COLOR_OPTION: {
        const filterOptions = filterModel.options.filter(option => filterModel.activeOptions.indexOf(option.key) > -1);

        if (filterOptions.length === 1) {
          activeFilterOption = filterOptions[0].value;
        } else if (filterOptions.length === 0) {
          activeFilterOption = `Default`;
        } else {
          activeFilterOption = filterOptions.map(filter => filter.value).join('|');
        }

        break;
      }
      case FilterType.PRICE: {
        activeFilterOption = `${(filterModel.activeOptions as PriceFilterModel).minPrice}-${
          (filterModel.activeOptions as PriceFilterModel).maxPrice
        }`;
      }
    }

    this.setAndUpdateQueryParamsInLocation(filterModel.title, activeFilterOption);
  };

  private readonly getPath = (): string => {
    if (this.location.path.length > 0) {
      return `/${this.location.path[this.location.path.length - 1]}`;
    }

    return `/`;
  };

  private readonly getSearchParamsDecoded = (searchParams: {
    [paramKey: string]: string;
  }): {[paramKey: string]: string} => {
    return Object.keys(searchParams).reduce((res, key) => {
      return {...res, [decodeURIComponent(key)]: decodeURIComponent(searchParams[key])};
    }, {});
  };

  private readonly getSearchParams = (): string => {
    let url = '';
    this.currentUrlSearchParams.forEach(queryParam => {
      url += `${queryParam.title}=${queryParam.value}&`;
    });

    return url.slice(0, -1);
  };

  private readonly setAndUpdateQueryParams = (title: string, value: string) => {
    const index = this.currentUrlSearchParams.findIndex(param => param.title === title);

    if (index === -1) {
      this.currentUrlSearchParams.push({title, value});
    } else {
      this.currentUrlSearchParams[index] = {title, value};
    }
  };
  private readonly setAndUpdateQueryParamsInLocation = (title: string, value: string) => {
    this.setAndUpdateQueryParams(title, value);

    this.updateLocation(`${this.getPath()}?${this.getSearchParams()}`);
  };

  private readonly updateLocation = (url: string) => {
    this.location.to(url);
  };
}
