import {ICollectionIdsFilterDTO, IProduct, ISorting, IOldGetInitialData, IGetInitialData} from '../types/galleryTypes';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {DataApi} from '../api/DataApi';
import {GetCategoryProductsQueryVariables, GetDataQuery, ProductFilters} from '../graphql/queries-schema';
import {TRACK_EVENT_COLLECTION} from '../constants';
import {APP_DEFINITION_ID, STORAGE_PAGINATION_KEY} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/es/src/types/site-map';
import {ProductActions} from '@wix/wixstores-client-core/dist/es/src/product-actions/ProductActions';

export class ProductsService {
  private readonly dataApi: DataApi;
  public products: IProduct[];
  public totalCount: number;
  public collectionName: string;
  private filters: ProductFilters = null;
  private collectionIds: ICollectionIdsFilterDTO;
  private sorting?: ISorting;
  public showShowLightEmptyState = false;
  private readonly productActions: ProductActions;

  constructor(
    private readonly siteStore: SiteStore,
    private productsPerPage: number,
    private readonly consumerName: string
  ) {
    this.dataApi = new DataApi(this.siteStore);
    this.productActions = new ProductActions(this.siteStore);
  }

  public async getProducts(
    filters: ProductFilters,
    collectionIds: ICollectionIdsFilterDTO,
    sorting?: ISorting
  ): Promise<IProduct[]> {
    const response = await this.dataApi.getProducts(0, this.productsPerPage, filters, collectionIds, sorting);
    this.totalCount = response.totalCount;
    this.products = response.list;
    this.filters = filters;
    this.collectionIds = collectionIds;
    this.sorting = sorting;
    this.sendTrackEvent(0);
    return this.products;
  }

  public async oldGetInitialData(options: IOldGetInitialData): Promise<GetDataQuery> {
    const {data} = await this.dataApi.oldGetInitialData({...options, limit: this.productsPerPage});
    return this.initState(data);
  }

  public async getInitialData(options: IGetInitialData): Promise<GetDataQuery> {
    let limit;
    if (options.limit) {
      limit = options.limit;
    } else {
      limit = this.productsPerPage;
    }
    const {data} = await this.dataApi.getInitialData({...options, limit});
    return this.initState(data);
  }

  public async getRelatedItems(options: {
    externalId: string;
    productIds: string[];
  }): Promise<Pick<GetDataQuery, 'catalog' | 'appSettings'>> {
    const {data} = await this.dataApi.getRelatedItems({...options});
    const parsedData: Pick<GetDataQuery, 'catalog' | 'appSettings'> = {
      catalog: {
        category: {
          productsWithMetaData: {
            list: data.catalog.relatedProducts,
            totalCount: 16,
          },
          name: '',
          id: '',
        },
      },
      appSettings: data.appSettings,
    };
    this.initState(parsedData);
    return parsedData;
  }

  private initState(data: GetDataQuery): GetDataQuery {
    if (data.catalog.category === null) {
      data.catalog.category = {productsWithMetaData: {list: [], totalCount: 0}, id: '', name: ''};
      this.showShowLightEmptyState = true;
    }

    this.products = data.catalog.category.productsWithMetaData.list;
    this.collectionName = data.catalog.category.name;
    this.totalCount = data.catalog.category.productsWithMetaData.totalCount;
    this.collectionIds = {mainCategory: data.catalog.category.id};
    this.sendTrackEvent(0);
    return data;
  }

  public sendTrackEvent(fromIndex: number): void {
    if (this.siteStore.isSSR()) {
      return;
    }
    const items = this.products.slice(fromIndex).map((p, i) => ({
      id: p.id,
      name: p.name,
      list: this.consumerName,
      category: TRACK_EVENT_COLLECTION,
      position: i + fromIndex,
      price: p.comparePrice || p.price,
      currency: this.siteStore.currency,
    }));
    this.siteStore.windowApis.trackEvent('AddProductImpression', {
      appDefId: APP_DEFINITION_ID,
      contents: items,
      origin: 'Stores',
    });
  }

  public hasMoreProductsToLoad(): boolean {
    return this.products.length < this.totalCount;
  }

  public setProductsPerPage(productsPerPage: number): void {
    this.productsPerPage = productsPerPage;
  }

  public getProductPerPage(): number {
    return this.productsPerPage;
  }

  private removeNotUIVisibleProducts(visibleProducts: number) {
    if (visibleProducts !== this.products.length) {
      this.products = this.products.slice(0, visibleProducts);
    }
  }

  public getProduct(id: string): IProduct {
    return this.products.filter(p => p.id === id)[0];
  }

  public async getCategoryProducts({compId, limit, offset}: GetCategoryProductsQueryVariables): Promise<void> {
    const {data} = await this.dataApi.getCategoryProducts({
      compId,
      limit,
      offset,
    });
    const retrievedProducts = data.catalog.category.productsWithMetaData.list;
    this.products.splice(offset, retrievedProducts.length, ...retrievedProducts);
  }

  public async loadMoreProducts(visibleProducts: number): Promise<IProduct[]> {
    this.removeNotUIVisibleProducts(visibleProducts);
    const apiResponse = await this.dataApi.getProducts(
      visibleProducts,
      visibleProducts + this.productsPerPage,
      this.filters,
      this.collectionIds,
      this.sorting
    );
    if (apiResponse.list.length === 0) {
      return null;
    }
    this.products = this.products.concat(apiResponse.list);
    this.sendTrackEvent(visibleProducts);
    return this.products;
  }

  public async sortProducts(sorting: ISorting): Promise<IProduct[]> {
    await this.getProducts(this.filters, this.collectionIds, sorting);
    return this.products;
  }

  public async filterProducts(filters: ProductFilters, collectionIds: ICollectionIdsFilterDTO): Promise<IProduct[]> {
    this.products = await this.getProducts(filters, collectionIds, this.sorting);
    return this.products;
  }

  public getMainCollectionId(): string {
    return this.collectionIds.mainCategory;
  }

  public storeNavigation(pageId: string): void {
    const paginationMap = this.products.map(p => p.urlPart);
    const history: IStoreFrontNavigationContext = {
      pageId,
      paginationMap,
    };
    this.siteStore.storage.local.setItem(STORAGE_PAGINATION_KEY, JSON.stringify(history));
  }

  public quickViewProduct(productId: string, urlPart?: string, compId?: string, externalId?: string): Promise<any> {
    return this.productActions.quickViewProduct(productId, 'gallery', urlPart, compId, externalId);
  }
}
